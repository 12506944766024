/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Typography } from '@mui/material'
import { useWeb3React } from '@web3-react/core'
import React from 'react'
import bgImage from '../../../assets/doors.png'
import StoreBuyButton from './components/StoreBuyButton'
import NFTStoreInfo from './info/NFTStoreInfo'

function NFTStore() {
  const { active } = useWeb3React()

  return (
    <div
      css={css`
        border: 1px solid #ccc;
        //padding: 20px;
        border-radius: 6px;
        margin: auto;
        background-color: #3c3552;
        width: 400px;
        position: relative;
        z-index: 10;
        overflow: hidden;
      `}
    >
      <div
        css={css`
          position: relative;
          min-height: 400px;
          overflow: hidden;
        `}
      >
        <img
          src={bgImage}
          alt={'bg'}
          css={css`
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            z-index: -1;
            object-fit: cover;
          `}
        />

        {/* <Grid */}
        {/*  container */}
        {/*  flexDirection={'column'} */}
        {/*  justifyContent={'space-between'} */}
        {/*  css={css` */}
        {/*    height: 100%; */}
        {/*  `} */}
        {/* > */}
        {/* */}
        {/* </Grid> */}
      </div>

      <div
        css={css`
          background: #1a1a22;
          background: #32333b;
          padding: 15px;
        `}
      >
        <Typography
          variant={'h1'}
          css={css`
            margin-bottom: 20px;
          `}
        >
          NFT Pool
        </Typography>

        <div>
          <NFTStoreInfo />
        </div>

        <div>{active ? <StoreBuyButton /> : null}</div>
      </div>
    </div>
  )
}

export default NFTStore
