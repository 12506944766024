/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { Button, Grid, Typography } from '@mui/material'
import ActionsDispatcher from '@reducers/actions/dispatcher'
import { ReduxState } from '@reducers/index'
import PoolInfoDispatcher from '@reducers/poolInfo/dispatcher'
import invariant from 'invariant'
import React, { useEffect } from 'react'
import { useAsyncFetch, useAsyncHandler } from 'react-hooks-async-handlers'
import { useSelector } from 'react-redux'
import LiquidityIcon from '../components/LiquidityIcon/LiquidityIcon'
import Loader from '../components/Loader'
import ModalStyled from '../elements/ModalStyled'
import RendererActionSplit from '../libs/RendererActionSplit'
import { useAppDispatch } from '../libs/redux'
import { useContracts } from '../pages/home/ContractContext'

interface Props {
  id: number
  isVisible: boolean
  onClose(): any
}

function NFTSellModal(props: Props) {
  const { id, isVisible, onClose } = props

  const contracts = useContracts()
  const dispatch = useAppDispatch()
  useAsyncFetch(
    async () => {
      if (!contracts) return null
      await dispatch(PoolInfoDispatcher.getPrice(contracts.storeContract))
    },
    {
      maxTries: 3,
      timeoutBeforeRetry: 3000,
    },
    [contracts?.storeContract?.address],
  )

  const handleSold = () => {
    dispatch(ActionsDispatcher.actions.addSoldNFT(id))
    onClose()
  }

  const sellAction = useAsyncHandler(async () => {
    invariant(contracts, 'account is not set')
    const { nftContract, storeContract } = contracts

    const approvedAddress = await nftContract.getApproved(id)
    const isApproved = approvedAddress === storeContract.address

    if (!isApproved) {
      const txApprove = await nftContract.approve(storeContract.address, id)
      console.log('txApprove', txApprove)
    }

    const sellTx = await storeContract.sell(id)
    await sellTx.wait()

    handleSold()
  })

  const { poolInfo } = useSelector((state: ReduxState) => ({
    poolInfo: state.poolInfo,
  }))
  const { price } = poolInfo

  useEffect(() => {
    sellAction.reset()
  }, [isVisible, id])

  return (
    <ModalStyled
      isVisible={isVisible}
      onClose={onClose}
      showCloseButton
      css={css`
        max-width: 400px;
      `}
    >
      <Typography
        variant={'h2'}
        css={css`
          margin-bottom: 30px;
        `}
      >
        Sell NFT #{id}
      </Typography>

      <Grid container alignItems={'center'}>
        <Typography fontWeight={'bold'}>Pool price:</Typography>

        <LiquidityIcon
          css={css`
            width: 20px;
            height: 20px;
            margin: 0 7px 0 10px;
          `}
        />

        <Typography>{price}</Typography>
      </Grid>
      <div
        css={css`
          margin-top: 30px;
          padding: 0 20px;
          text-align: center;
        `}
      >
        <Typography>Are you sure want to sell this nft with a pool price?</Typography>
      </div>
      <Grid
        container
        justifyContent={'center'}
        css={css`
          margin-top: 20px;
          padding: 10px 20px;
        `}
      >
        <RendererActionSplit
          statuses={sellAction}
          renderLoading={() => <Loader />}
          renderError={(error) => <Typography color={'error'}>{error}</Typography>}
          render={() => {
            return (
              <Button variant={'contained'} fullWidth onClick={sellAction.execute}>
                Sell
              </Button>
            )
          }}
        />
      </Grid>
    </ModalStyled>
  )
}

export default NFTSellModal
