import { DispatcherResponse } from '../../typings/ReduxDispatch'
import api from './api'
import { actions } from './reducer'

class Dispatch {
  getAllNFT(address: string): DispatcherResponse {
    return async (dispatch) => {
      const list = await api.getAllNFT(address)
      await dispatch(actions.setNFT({ address, list }))
    }
  }
}

const AddressDispatcher = new Dispatch()
export default AddressDispatcher
