import appTypes from '@reducers/app/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface State {
  price?: string
  priceStoreUpdatedAt?: StoreUpdatedAt

  nftCount?: string
  nftCountStoreUpdatedAt?: StoreUpdatedAt

  liquidity?: string
  liquidityStoreUpdatedAt?: StoreUpdatedAt
}

const initialState: State = {}

const slice = createSlice({
  name: 'POOL_INFO',
  initialState,
  extraReducers: {
    [appTypes.RESET]: () => initialState,
  },
  reducers: {
    setPrice(state, action: PayloadAction<State['price']>) {
      const price = action.payload

      state.price = price
      state.priceStoreUpdatedAt = Date.now()
    },

    setLiquidity(state, action: PayloadAction<State['liquidity']>) {
      const liquidity = action.payload

      state.liquidity = liquidity
      state.liquidityStoreUpdatedAt = Date.now()
    },

    setNFTCount(state, action: PayloadAction<State['nftCount']>) {
      const nftCount = action.payload

      state.nftCount = nftCount
      state.nftCountStoreUpdatedAt = Date.now()
    },
  },
})

export const { reducer, actions } = slice
export default reducer
