import appTypes from '@reducers/app/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface StateAddressItem {
  nftList: NFT[]
  nftUpdatedAt?: StoreUpdatedAt
}

interface State {
  [key: string]: StateAddressItem
}

const initialState: State = {}

const slice = createSlice({
  name: 'ADDRESS',
  initialState,
  extraReducers: {
    [appTypes.RESET]: () => initialState,
  },
  reducers: {
    setNFT(
      state,
      action: PayloadAction<{
        address: string
        list: NFT[]
      }>,
    ) {
      const { address, list } = action.payload
      if (!state[address]) state[address] = { nftList: [] }

      state[address].nftList = list
      state[address].nftUpdatedAt = Date.now()
    },
  },
})

export const { reducer, actions } = slice
export default reducer
