import _ from 'lodash'
import React, { ReactElement } from 'react'
import { ErrorMessageProvider } from 'react-hooks-use-error'
import apiErrorUtil from './errors/apiErrorUtil'

export interface Props {
  children: ReactElement
}

const getErrorMessage = (e) => {
  const { message = '' } = e

  if (e?.code === 'ACTION_REJECTED') {
    return '' // MetaMask rejected
  }

  if (message.includes('JSON-RPC')) {
    const { reason, code, method, transaction, error } = e

    // UNPREDICTABLE_GAS_LIMIT - no enought coins or other...

    // web3 error
    // code is more reasonable in this case
    return reason || code || message
  }

  // const { status } = apiErrorUtil.getRequestInfo(e)
  // const errorCode = apiErrorUtil.getErrorCodeFromApi(e)
  // console.log('getErrorMessage:', status, errorCode)
  const errorMessage = apiErrorUtil.getFormattedApiError(e)

  return errorMessage
}

export default function ErrorMessageProviderWrapper(props: Props) {
  const { children } = props

  return <ErrorMessageProvider getErrorMessage={getErrorMessage}>{children}</ErrorMessageProvider>
}
