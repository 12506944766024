/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Typography } from '@mui/material'
import React from 'react'

function HomeInfo() {
  return (
    <div
      css={css`
        padding: 50px 20px;
      `}
    >
      <Typography
        variant={'h1'}
        css={css`
          margin-bottom: 20px;
        `}
      >
        Fntzy - is an liquid NFT collection.
      </Typography>
      <Typography>
        NFT collection contains 10000 items. <br />
        Here you can find nft pool, where you always can sell your nft by a floor price, or buy previously sold nft.
        <br />
        <br />
        50% of all royalties will go to pool liquidity. So nft floor price will increase with each nft sold.
      </Typography>
    </div>
  )
}

export default HomeInfo
