import { combineReducers } from 'redux'
import actions from './actions/reducer'
import address from './address/reducer'
import app from './app/reducer'
import poolInfo from './poolInfo/reducer'

const AppReducer = combineReducers({
  app,
  address,
  poolInfo,
  actions,
})

export type ReduxState = ReturnType<typeof AppReducer>

export default AppReducer
