/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid, Typography } from '@mui/material'
import AddressDispatcher from '@reducers/address/dispatcher'
import { ReduxState } from '@reducers/index'
import { useWeb3React } from '@web3-react/core'
import _ from 'lodash'
import React, { useMemo } from 'react'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import { useDispatch, useSelector } from 'react-redux'
import RendererStatusSplit from 'react-renderer-status-split'
import Loader from '../../../components/Loader'
import { ReduxDispatch } from '../../../typings/ReduxDispatch'
import { StyledContainer } from './components/Item.styles'
import MyNFTListItem from './components/MyNFTListItem'

interface Props {
  address: string
}
function MyNFTList(props: Props) {
  const { address } = props

  const dispatch = useDispatch<ReduxDispatch>()
  const actionFetch = useAsyncFetch(async () => {
    if (!address) return null
    await dispatch(AddressDispatcher.getAllNFT(address))
  }, [address])

  const { list, actions } = useSelector((state: ReduxState) => ({
    list: state.address[address]?.nftList || [],
    actions: state.actions,
  }))

  const listFiltered = useMemo(() => {
    let listM = list
    if (!_.isEmpty(actions.nftSold)) {
      listM = _.filter(listM, (item) => !_.includes(actions.nftSold, item.id))
    }

    return listM
  }, [list, actions])

  return (
    <div>
      <RendererStatusSplit
        statuses={actionFetch}
        isEmpty={_.isEmpty(listFiltered)}
        renderError={(error) => <Typography color={'error'}>{error}</Typography>}
        renderEmpty={() => <Typography>Seems you don't have nft yet.</Typography>}
        renderLoading={() => (
          <Grid container justifyContent={'center'}>
            <Loader />
          </Grid>
        )}
        render={() => (
          <Grid container justifyContent={'space-between'}>
            {_.map(listFiltered, (item) => (
              <MyNFTListItem nft={item} key={`my-nft-list-item-${item.id}`} />
            ))}

            <StyledContainer />
            <StyledContainer />
          </Grid>
        )}
      />
    </div>
  )
}

export default MyNFTList
