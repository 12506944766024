import styled from '@emotion/styled'

export const StyledContainer = styled.div`
  padding: 20px;
  margin-right: 10px;
  margin-bottom: 30px;
  min-width: 300px;
`

export const StyledItemContainer = styled(StyledContainer)`
  min-height: 300px;
  border: 1px solid #4a4251;
  background-color: #fff;
  position: relative;
`
