/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Button, Grid, Typography } from '@mui/material'
import { ReduxState } from '@reducers/index'
import { useWeb3React } from '@web3-react/core'
import BN from 'bn.js'
import { ethers } from 'ethers'
import React, { useMemo } from 'react'
import { useAsyncFetch, useAsyncHandler } from 'react-hooks-async-handlers'
import { useSelector } from 'react-redux'
import Loader from '../../../../components/Loader'
import config from '../../../../config'
import RendererActionSplit from '../../../../libs/RendererActionSplit'
import { useContracts } from '../../ContractContext'

interface Props {}
function StoreBuyButton(props: Props) {
  const web3Info = useWeb3React()
  const { account } = web3Info
  const { poolInfo } = useSelector((state: ReduxState) => ({
    poolInfo: state.poolInfo,
  }))
  const { nftCount, price } = poolInfo

  const contracts = useContracts()

  const priceBN = useMemo(() => {
    if (!price) return null
    const bn = ethers.utils.parseEther(price)
    return new BN(bn.toString())
  }, [price])

  const actionBuy = useAsyncHandler(async () => {
    if (!contracts) return contracts
    if (!priceBN) return null
    const { liquidityTokenContract, storeContract } = contracts
    const allowance: BN = await liquidityTokenContract.allowance(account, config.chain.addressStore)
    const allowBN = new BN(allowance.toString())
    if (allowBN.lt(priceBN)) {
      let allowAmountBN = priceBN.mul(new BN(10))

      console.log(
        'contracts.storeContract.address, allowAmountBN',
        contracts.storeContract.address,
        allowAmountBN,
        allowAmountBN.toString(),
      )
      const tx = await liquidityTokenContract.approve(storeContract.address, allowAmountBN.toString())
      console.log('waiting for approve tx...', tx)
      await tx.wait()
    }

    const tx = await storeContract.buy()
    console.log('tx', tx)
    const txRes = await tx.wait()
    console.log('txRes', txRes)
  })

  const isAvailable = useMemo(() => nftCount && Number(nftCount) > 0, [nftCount])
  const isDisabled = !isAvailable || !priceBN

  return (
    <Grid
      container
      justifyContent={'center'}
      css={css`
        padding: 20px;
        margin-top: 10px;
      `}
    >
      <RendererActionSplit
        statuses={actionBuy}
        renderLoading={() => <Loader />}
        renderError={(error) => {
          return <Typography color={'error'}>{error}</Typography>
        }}
        renderSuccess={() => {
          return (
            <Grid container justifyContent={'center'}>
              <Typography fontWeight={'bold'}>Congratulations.</Typography>
              <Typography>NFT is sent to you. It will appear in your wallet soon.</Typography>
            </Grid>
          )
        }}
        render={() => {
          return (
            <Button
              variant={'contained'}
              disabled={isDisabled}
              onClick={actionBuy.execute}
              css={css`
                min-width: 150px;
              `}
            >
              Buy
            </Button>
          )
        }}
      />
    </Grid>
  )
}

export default StoreBuyButton
