/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Button, Typography, Grid } from '@mui/material'
import React from 'react'
import useModalState from '../../../../hooks/useModalState'
import NFTSellModal from '../../../../modals/NFTSellModal'

interface Props {
  id: number
}
function NFTActions(props: Props) {
  const { id } = props
  const sellModal = useModalState(false)

  return (
    <Grid container justifyContent={'center'}>
      <Button
        variant={'contained'}
        onClick={sellModal.onOpen}
        css={css`
          min-width: 200px;
        `}
      >
        Sell to pool
      </Button>
      <NFTSellModal id={id} {...sellModal} />
    </Grid>
  )
}

export default NFTActions
