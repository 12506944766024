import React, { useMemo } from 'react'
import config from '../../config'
import bnbIcon from './assets/bnb.png'
import busdIcon from './assets/busd.png'

interface Props {
  symbol?: string
  className?: string
}
function LiquidityIcon(props: Props) {
  const { symbol = config.chain.liquidity.symbol, className } = props

  const iconURL = useMemo(() => {
    if (symbol == 'BUSD') {
      return busdIcon
    }
    if (symbol === 'BNB') {
      return bnbIcon
    }

    return null
  }, [symbol])

  if (!iconURL) return null

  return <img src={iconURL} alt={symbol} className={className} />
}

export default LiquidityIcon
