import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import { Web3ReactProvider, createWeb3ReactRoot } from '@web3-react/core'
import React from 'react'
import { Provider } from 'react-redux'
import Web3 from 'web3'
import theme from './app/theme'
import ErrorMessageProvider from './libs/ErrorMessageProvider'
import { MetaMaskProvider } from './metamask-ctx'
import ContractContext from './pages/home/ContractContext'
import store from './store'
// import { hooks as metaMaskHooks, metaMask } from './metaMask'

function getLibrary(provider, connector) {
  return new Web3(provider)
}

const Web3ReactProviderReloaded = createWeb3ReactRoot('anotherOne')

export default function AppContext({ children }: { children: React.ReactElement }) {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <Web3ReactProvider getLibrary={getLibrary}>
            <Web3ReactProviderReloaded getLibrary={getLibrary}>
              <MetaMaskProvider>
                <ContractContext>
                  <ErrorMessageProvider>{children}</ErrorMessageProvider>
                </ContractContext>
              </MetaMaskProvider>
            </Web3ReactProviderReloaded>
          </Web3ReactProvider>
        </Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}
