/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid, Typography } from '@mui/material'
import React from 'react'
import LiquidityIcon from '../../../../components/LiquidityIcon/LiquidityIcon'
import config from '../../../../config'

interface Props {
  title: string
  value: string | number | undefined
  showLiquidityIcon?: boolean
}
function StoreInfoItem(props: Props) {
  const { title, value, showLiquidityIcon } = props

  return (
    <div>
      <Typography fontWeight={'bold'}>{title}</Typography>
      <Grid container alignItems={'center'}>
        {showLiquidityIcon ? (
          <LiquidityIcon
            symbol={config.chain.liquidity.symbol}
            css={css`
              width: 20px;
              height: 20px;
              object-fit: contain;
              margin-right: 7px;
            `}
          />
        ) : null}
        <Typography>{value || '-'}</Typography>
      </Grid>
    </div>
  )
}

export default StoreInfoItem
