/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Button, Grid, Typography } from '@mui/material'
import React, { useMemo } from 'react'

interface Props {
  address: string
  onDisconnect(): any
}

function WalletConnected(props: Props) {
  const { address, onDisconnect } = props

  const addressShort = useMemo(() => {
    return `0x...${address.slice(address.length - 6)}`
  }, [address])

  return (
    <div>
      <Grid container alignItems={'center'}>
        <div>
          <Typography
            variant={'subtitle2'}
            css={css`
              font-size: 12px;
              color: #ccc;
            `}
          >
            wallet:
          </Typography>
          <Typography
            css={css`
              color: #fff;
              margin-right: 10px;
            `}
          >
            {addressShort}
          </Typography>
        </div>

        <Button
          onClick={onDisconnect}
          variant={'contained'}
          size={'small'}
          css={css`
            background-color: #ccc;

            &:hover {
              background-color: #bcbcbc;
            }
          `}
        >
          Disconnect
        </Button>
      </Grid>
    </div>
  )
}

export default WalletConnected
