/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid, Typography } from '@mui/material'
import React from 'react'
import config from '../../config'
import ConnectWallet from '../ConnectWallet'

function NetworkConnect() {
  return (
    <Grid container alignItems={'center'}>
      <div
        css={css`
          padding-right: 30px;
        `}
      >
        <Typography
          variant={'subtitle2'}
          css={css`
            font-size: 12px;
            color: #ccc;
          `}
        >
          network:
        </Typography>

        <Typography>{config.chain.name}</Typography>
      </div>

      <ConnectWallet />
    </Grid>
  )
}

export default NetworkConnect
