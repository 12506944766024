/** @jsx jsx */
import * as url from 'url'
import { jsx, css } from '@emotion/react'
import { Typography } from '@mui/material'
import React, { useMemo } from 'react'
import config from '../../../../config'
import { StyledItemContainer } from './Item.styles'
import NFTActions from './NFTActions'

interface Props {
  nft: NFT
}
function MyNFTListItem(props: Props) {
  const { nft } = props
  const { id } = nft

  const nftUrl = useMemo(() => {
    let index = Number(id) % 4
    return url.resolve(config.api_url, `/image/${index}.png`)
  }, [id])

  return (
    <StyledItemContainer
      css={css`
        background: url('${nftUrl}') no-repeat center;
        background-size: cover;
      `}
    >
      {/* <img */}
      {/*  src={nftUrl} */}
      {/*  alt={`Fantasy NFT #${id}`} */}
      {/*  css={css` */}
      {/*    position: absolute; */}
      {/*    top: 0; */}
      {/*    left: 0; */}
      {/*    right: 0; */}
      {/*    bottom: 0; */}
      {/*    object-fit: cover; */}
      {/*  `} */}
      {/* /> */}
      <div>
        <Typography>#{id}</Typography>

        <div
          css={css`
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            padding: 10px;
            background-color: rgba(255, 255, 255, 0.3);
          `}
        >
          <NFTActions id={id} />
        </div>
      </div>
    </StyledItemContainer>
  )
}

export default MyNFTListItem
