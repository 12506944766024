import { useWeb3React } from '@web3-react/core'
import { ethers } from 'ethers'
import React, { useMemo, ReactElement, useContext } from 'react'
import config from '../../config'
import NFTContract from '../../contracts/GameItem.json'
import BUSDToken from '../../contracts/BUSDToken.json'
// import IERC20 from '../../contracts/IERC20.json'
import NFTStoreContract from '../../contracts/NFTStore.json'

export const ContractContext = React.createContext<{
  nftContract: ethers.Contract
  storeContract: ethers.Contract
  liquidityTokenContract: ethers.Contract
} | null>(null)

interface Props {
  children: ReactElement
}
function ContractProvider(props: Props) {
  const { children } = props
  const web3Info = useWeb3React()

  const contracts = useMemo(() => {
    const { ethereum } = window
    if (!ethereum) return null
    const provider = new ethers.providers.Web3Provider(ethereum)

    const isSigner = web3Info.active
    let rpcProvider = isSigner ? provider.getSigner() : provider

    const nftContract = new ethers.Contract(config.chain.addressNFT, NFTContract.abi, rpcProvider)
    const storeContract = new ethers.Contract(config.chain.addressStore, NFTStoreContract.abi, rpcProvider)
    const liquidityTokenContract = new ethers.Contract(config.chain.liquidity.address, BUSDToken.abi, rpcProvider)

    return {
      isSigner,
      nftContract,
      storeContract,
      liquidityTokenContract,
    }
  }, [web3Info.active])

  return <ContractContext.Provider value={contracts}>{children}</ContractContext.Provider>
}

export function useContracts() {
  const contracts = useContext(ContractContext)
  return contracts
}

export default ContractProvider
