/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Button, Grid, Typography } from '@mui/material'
import { utils } from 'ethers'
import React from 'react'
import { useAsyncHandler } from 'react-hooks-async-handlers'
import Loader from '../../../components/Loader'
import RendererActionSplit from '../../../libs/RendererActionSplit'
import { useContracts } from '../ContractContext'

interface Props {}

function LiquidityMint(props: Props) {
  const contracts = useContracts()
  const mintAction = useAsyncHandler(async () => {
    if (!contracts) return null
    const quantityBN = utils.parseUnits('10000', 18)
    const tx = await contracts.liquidityTokenContract.mint(quantityBN)
    await tx.wait()
  })

  if (!contracts) return null

  return (
    <div
      css={css`
        margin-top: 30px;
        background: #32333b;
        padding: 15px;
        max-width: 400px;
        border-radius: 8px;
      `}
    >
      <Grid
        container
        alignItems={'center'}
        css={css`
          margin-bottom: 10px;
        `}
      >
        <Typography variant={'h2'} fontWeight={'bold'}>
          Liquidity mint
        </Typography>
        <div
          css={css`
            background: #c92f2f;
            padding: 3px 15px;
            border-radius: 15px;
            margin-left: 15px;
          `}
        >
          <Typography
            css={css`
              display: inline-block;
              font-size: 10px;
              line-height: 14px;
              vertical-align: middle;
            `}
          >
            Testnet only
          </Typography>
        </div>
      </Grid>

      <Typography>Testnet allow you to mint some BUSD to test contract.</Typography>

      <div
        css={css`
          padding-top: 20px;
        `}
      >
        <RendererActionSplit
          statuses={mintAction}
          renderError={(error) => <Typography color={'error'}>{error}</Typography>}
          renderSuccess={() => <Typography textAlign={'center'}>minted</Typography>}
          renderLoading={() => (
            <Grid container justifyContent={'center'}>
              <Loader />
            </Grid>
          )}
          render={() => {
            return (
              <Grid container justifyContent={'center'}>
                <Button
                  variant={'contained'}
                  onClick={mintAction.execute}
                  css={css`
                    min-width: 150px;
                  `}
                >
                  Mint
                </Button>
              </Grid>
            )
          }}
        />
      </div>
    </div>
  )
}

export default LiquidityMint
