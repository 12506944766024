import appTypes from '@reducers/app/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface State {
  nftSold: number[]
  nftBought: number[]
}

const initialState: State = {
  nftSold: [],
  nftBought: [],
}

const slice = createSlice({
  name: 'ACTIONS',
  initialState,
  extraReducers: {
    [appTypes.RESET]: () => initialState,
  },
  reducers: {
    addSoldNFT(state, action: PayloadAction<number>) {
      const id = action.payload
      state.nftSold.push(id)
    },
  },
})

export const { reducer, actions } = slice
export default reducer
