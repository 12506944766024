/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid } from '@mui/material'
import AddressDispatcher from '@reducers/address/dispatcher'
import { useWeb3React } from '@web3-react/core'
import React from 'react'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import { useDispatch } from 'react-redux'
import cityBG from '../../assets/city.png'
import treeBg from '../../assets/trees.png'
import AppBar from '../../components/appBar/AppBar'
import useChainConfig from '../../hooks/useChainConfig'
import { ReduxDispatch } from '../../typings/ReduxDispatch'
import FooterContacts from './home/FooterContacts'
import HomeInfo from './home/HomeInfo'
import LiquidityMint from './liquidityMint/LiquidityMint'
import NFTStore from './nftStore/NFTStore'
// import { useWallet, UseWalletProvider } from 'use-wallet'

// import { hooks, metaMask } from '../../metaMask'

function Home() {
  const web3Info = useWeb3React()
  const { account } = web3Info
  const chainConfig = useChainConfig()

  const dispatch = useDispatch<ReduxDispatch>()
  useAsyncFetch(async () => {
    if (!account) return null
    await dispatch(AddressDispatcher.getAllNFT(account))
  }, [account])

  return (
    <Grid
      container
      flexDirection={'column'}
      css={css`
        flex-grow: 1;
        position: relative;
        overflow: hidden;
        z-index: 5;
        padding-bottom: 150px;
      `}
    >
      <img
        src={treeBg}
        // src={cityBG}
        alt=""
        css={css`
          position: absolute;
          z-index: -1;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          object-fit: cover;
          width: 100%;
        `}
      />
      <AppBar />
      <div
        css={css`
          padding-top: 30px;
          //background: url('${treeBg}') no-repeat center;
          background-size: cover;
          flex-grow: 1;
        `}
      >
        <Grid container>
          <Grid sm={4}>
            <HomeInfo />
          </Grid>
          <Grid sm={3} />
          <Grid>
            <NFTStore />

            {chainConfig.liquidity.isMintAble && <LiquidityMint />}
          </Grid>
        </Grid>
      </div>

      <FooterContacts />
    </Grid>
  )
}

export default Home
