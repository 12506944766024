import { Contract, ethers } from 'ethers'
import _ from 'lodash'
import config from '../../config'
import { DispatcherResponse } from '../../typings/ReduxDispatch'
import { actions } from './reducer'

const { decimals } = config.chain.liquidity

class Dispatch {
  getPrice(storeContract: Contract): DispatcherResponse {
    return async (dispatch) => {
      const valueBN = await storeContract.getPrice()
      let valueParsedBN = ethers.utils.formatUnits(valueBN.toString(), decimals)
      let valueRounded = _.round(Number(valueParsedBN.toString()), 2)
      dispatch(actions.setPrice(valueRounded.toString()))
    }
  }

  getNFTCount(storeContract: Contract): DispatcherResponse {
    return async (dispatch) => {
      const valueBN = await storeContract.getNFTCount()
      console.log('countBN', valueBN)
      dispatch(actions.setNFTCount(valueBN.toString()))
    }
  }

  getLiquidity(storeContract: Contract): DispatcherResponse {
    return async (dispatch) => {
      const valueBN = await storeContract.getLiquidityBalanceOf()
      let valueParsed = ethers.utils.formatUnits(valueBN.toString(), decimals)
      valueParsed = Math.round(Number(valueParsed)).toString()
      dispatch(actions.setLiquidity(valueParsed))
    }
  }
}

const PoolInfoDispatcher = new Dispatch()
export default PoolInfoDispatcher
