import Button from '@mui/material/Button'
import React from 'react'
import { injected } from '../../connectors'

function SwitchNetworkButton() {
  return (
    <Button
      onClick={() => {
        // NOTE: presuming user is using injectedConnector
        injected
          .getProvider()
          .then((provider) => {
            provider.request({
              method: 'wallet_switchEthereumChain',
              params: [
                {
                  chainId: '0x61',
                },
              ],
            })
          })
          .catch((error: any) => {
            console.log(error)
          })
      }}
    >
      Switch to Mainnet
    </Button>
  )
}

export default SwitchNetworkButton
