/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { Typography } from '@mui/material'
import { useWeb3React } from '@web3-react/core'
import React from 'react'
import AppBar from '../../components/appBar/AppBar'
import MyNFTList from './nftList/MyNFTList'

function MePage() {
  const web3Info = useWeb3React()
  const { account } = web3Info

  return (
    <div>
      <AppBar />

      <div
        css={css`
          padding: 20px;
          padding-top: 50px;
        `}
      >
        <Typography
          variant={'h1'}
          css={css`
            margin-bottom: 20px;
          `}
        >
          My NFTs
        </Typography>

        {account ? <MyNFTList address={account} /> : <Typography>Please connect your wallet to see list.</Typography>}
      </div>
    </div>
  )
}

export default MePage
