import { Button } from '@mui/material'
import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core'
import React from 'react'
import useMetaMask from '../../metamask-ctx'
import SwitchNetworkButton from './SwitchNetworkButton'
import WalletConnected from './WalletConnected'

function ConnectWallet() {
  const info = useWeb3React()
  const { error, active, account = '' } = info
  // const chainId = useChainId()
  // const accounts = useAccounts()
  // const isActivating = useIsActivating()

  const metamask = useMetaMask()

  const handleClickConnect = () => {
    metamask.connect()
  }

  const handleClickDisconnect = () => {
    metamask.disconnect()
  }

  if (error && error instanceof UnsupportedChainIdError) {
    return <SwitchNetworkButton />
  }

  if (active) {
    return <WalletConnected address={account} onDisconnect={handleClickDisconnect} />
  }

  return (
    <div>
      <Button onClick={handleClickConnect} variant={'contained'}>
        Connect
      </Button>
      {/* <FooterContacts /> */}
    </div>
  )
}

export default ConnectWallet
