/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid } from '@mui/material'
import { ReduxState } from '@reducers/index'
import PoolInfoDispatcher from '@reducers/poolInfo/dispatcher'
import React from 'react'
import { useAsyncIntervalFetch } from 'react-hooks-async-handlers'
import { useDispatch, useSelector } from 'react-redux'
import RendererStatusSplit from 'react-renderer-status-split'
import Loader from '../../../../components/Loader'
import { ReduxDispatch } from '../../../../typings/ReduxDispatch'
import { useContracts } from '../../ContractContext'
import StoreInfoItem from './StoreInfoItem'

const FETCH_INTERVAL = 60000

function NFTStoreInfo() {
  const contracts = useContracts()
  const dispatch = useDispatch<ReduxDispatch>()

  const fetchPriceAction = useAsyncIntervalFetch(
    async () => {
      if (!contracts) return null
      await dispatch(PoolInfoDispatcher.getPrice(contracts.storeContract))
    },
    FETCH_INTERVAL,
    {
      maxTries: 3,
      timeoutBeforeRetry: 3000,
    },
  )

  const fetchNFTCountAction = useAsyncIntervalFetch(
    async () => {
      if (!contracts) return null
      await dispatch(PoolInfoDispatcher.getNFTCount(contracts.storeContract))
    },
    FETCH_INTERVAL,
    {
      maxTries: 3,
      timeoutBeforeRetry: 3000,
    },
  )

  const fetchLiquidity = useAsyncIntervalFetch(
    async () => {
      if (!contracts) return null
      await dispatch(PoolInfoDispatcher.getLiquidity(contracts.storeContract))
    },
    FETCH_INTERVAL,
    {
      maxTries: 3,
      timeoutBeforeRetry: 3000,
    },
  )

  const { poolInfo } = useSelector((state: ReduxState) => ({
    poolInfo: state.poolInfo,
  }))
  const { price, nftCount, liquidity } = poolInfo

  return (
    <Grid container justifyContent={'space-between'}>
      <RendererStatusSplit
        statuses={fetchNFTCountAction}
        isEmpty={!nftCount}
        renderLoading={() => <Loader />}
        render={() => (
          <div>
            <StoreInfoItem title={'NFTs in pool:'} value={nftCount} />
            {/* <Typography>NFTs in pool: {nftCount}</Typography> */}
          </div>
        )}
      />

      <RendererStatusSplit
        statuses={fetchPriceAction}
        isEmpty={!price}
        renderLoading={() => <Loader />}
        render={() => (
          <div>
            <StoreInfoItem title={'Pool NFT Price:'} value={price} showLiquidityIcon />
            {/* <Typography> {price}</Typography> */}
          </div>
        )}
      />

      <RendererStatusSplit
        statuses={fetchLiquidity}
        isEmpty={!liquidity}
        renderLoading={() => <Loader />}
        render={() => (
          <div>
            <StoreInfoItem title={'Total Liquidity:'} value={liquidity} showLiquidityIcon />
            {/* <Typography> {liquidity}</Typography> */}
          </div>
        )}
      />
    </Grid>
  )
}

export default NFTStoreInfo
