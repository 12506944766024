/**
 * process.env.API_URL should be only this style.
 * When there other way it can be not replaced. For example: const { API_URL } = process.env
 */
import invariant from 'invariant'
import config from './config'

interface ChainConfigs {
  rpcUrl?: string
  chainId: number
  addressNFT: string
  addressStore: string
  name: string
  liquidity: {
    address: string
    decimals: number
    symbol: string
    isMintAble?: boolean
  }
}

const chainConfigs: Record<'local' | 'development' | 'production', ChainConfigs> = {
  local: {
    rpcUrl: 'http://127.0.0.1:7545',
    chainId: 1337, // 5777,
    name: 'Local',
    addressNFT: '0x017a30f1045cA9F81098fd860C4d2d8bD5e593dE',
    addressStore: '0xA0660E83b7dC83AD3483D904a2187293294fAFE2',
    liquidity: {
      address: '0xf90085195Bc3CFB672eEF54Ca2657e8Ac1e0E329',
      decimals: 2,
      symbol: 'BUSD',
      isMintAble: true,
    },
  },
  production: {
    chainId: 56,
    name: 'BSC',
  },
  development: {
    chainId: 97,
    name: 'BSC Testnet',
    rpcUrl: 'https://data-seed-prebsc-1-s1.binance.org:8545',
    addressNFT: '0x370197F3C81136b1b7402A07E0DF2399c4B0D1F3',
    addressStore: '0xaa9070436acA3F368d0c74AE39f21B63b4B1CC1F',
    liquidity: {
      address: '0x028155433A151850e04d839d57b4b5E8BF771E3d',
      decimals: 18,
      symbol: 'BUSD',
      isMintAble: true,
    },
  },
}

export default chainConfigs
